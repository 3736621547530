import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { jwtDecode } from 'jwt-decode';
import { Alert, Snackbar } from '@mui/material';

function TableHac({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const pmaId = decodedToken.id;
  const [dataLoaded, setDataLoaded] = useState(false);

  const [hacs, setHacs] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/pma/hacs?pmaId=${pmaId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const sortedHacs = response.data.sort((a, b) => {
            // Convertir la fecha de dd-mm-aaaa a aaaa-mm-dd para la comparación
            const [dayA, monthA, yearA] = a.last_update.split('-').map(Number);
            const [dayB, monthB, yearB] = b.last_update.split('-').map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA); // Meses en JavaScript son 0-indexados
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateB - dateA; // Orden descendente por fecha
          });

          setHacs(sortedHacs);
          setHacs(response.data);
          setDataLoaded(true);
          onDataLoaded();
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    fetchData();
  }, [token, pmaId, onDataLoaded, t]);

  const openMenu = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  return (
    <>
      {dataLoaded || pmaId ? (
        hacs && hacs.length !== 0 ? (
          <div>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="user-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('common-name')}</TableCell>
                  <TableCell align="left">{t('common-last-name')}</TableCell>
                  <TableCell align="center">{t('common-phone')}</TableCell>
                  <TableCell align="center">{t('common-status')}</TableCell>
                  <TableCell align="center">
                    {t('common-last-update')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hacs.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.last_name}</TableCell>
                    <TableCell align="center">
                      {row.prefix_phone} {row.phone}
                    </TableCell>
                    <TableCell align="center">
                      {t(`common-${row.status}`)}
                    </TableCell>
                    <TableCell align="center">{row.last_update}</TableCell>
                    <TableCell align="right">
                      <span
                        aria-controls={
                          anchorEl[row.id] ? `menu${index}` : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          Boolean(anchorEl[row.id]) ? 'true' : undefined
                        }
                        onClick={openMenu(row.id)}
                      >
                        <span className="icon-more"></span>
                      </span>
                      <Menu
                        className="submenu"
                        id={`${row.id}`}
                        anchorEl={anchorEl[row.id]}
                        open={Boolean(anchorEl[row.id])}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem
                          onClick={() =>
                            navigate(`/pma/personal-details/${row.id}`)
                          }
                        >
                          {t('pma-hac-tab-option1')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`/pma/vehicles/${row.id}`)}
                        >
                          {t('pma-hac-tab-option2')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`/pma/preferences/${row.id}`)}
                        >
                          {t('pma-hac-tab-option3')}
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            navigate(`/pma/retailer-info/${row.id}`)
                          }
                        >
                          {t('pma-hac-tab-option4')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`/pma/pma-info/${row.id}`)}
                        >
                          {t('pma-hac-tab-option5')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`/pma/shared-notes/${row.id}`)}
                        >
                          {t('pma-hac-tab-option7')}
                        </MenuItem>
                        <MenuItem
                          onClick={
                            row.status !== 'exited'
                              ? () =>
                                  navigate(`/pma/unenroll/${row.id}`, {
                                    state: {
                                      name: row.name,
                                      lastName: row.last_name,
                                    },
                                  })
                              : null
                          }
                          className={row.status === 'exited' ? 'disabled' : ''}
                        >
                          {t('pma-hac-tab-option6')}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('pma-hac-tab-title-empty')}
              {/*<small>{t('pma-hac-tab-text-empty')}</small>*/}
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHac;
