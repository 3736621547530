import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import apiClient from '../../../../utils/apiClient';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Alert, Snackbar} from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

function TableHacActive({reload, onDataLoaded}) {
  const {t} = useTranslation();

  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const token = sessionStorage.getItem('token') || null;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/hac/retailer-salesperson?status=active`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const sortedRows = response.data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.last_update.split('-').map(Number);
            const [dayB, monthB, yearB] = b.last_update.split('-').map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateB - dateA; // Orden descendente por fecha
          });
          setRows(sortedRows);

          onDataLoaded();
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      } finally {
        setDataLoaded(true);
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded, t]);

  return (
    <>
      {dataLoaded ? (
        rows && rows.length !== 0 ? (
          <div>
            <div className="intro">
              <div className="text">
                <p>
                  <strong>{t('retailer-main-active-title')}</strong>
                </p>
                <p>{t('retailer-main-active-text')}</p>
              </div>
              <div className="actions">
                <Link to="/retailer/nominate-hac" className="btn small">
                  <span className="icon-plus"></span>{' '}
                  {t('retailer-main-active-action')}
                </Link>
              </div>
            </div>
            <div className="table-wrapper">
              <Table aria-label="simple table" className="user-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t('common-name')}</TableCell>
                    <TableCell align="left">{t('common-last-name')}</TableCell>
                    <TableCell align="center">{t('common-phone')}</TableCell>
                    <TableCell align="center">{t('common-pma')}</TableCell>
                    <TableCell align="center">{t('common-status')}</TableCell>
                    <TableCell align="center">
                      {t('common-last-update')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.last_name}</TableCell>
                      <TableCell align="center">
                        {row.prefix_phone} {row.phone}
                      </TableCell>
                      <TableCell align="center">{row.pma} </TableCell>
                      <TableCell align="center">
                        <span
                          className={
                            row.login_status === 'ready' ? 'logged' : ''
                          }
                        >
                          {row.login_status === 'ready'
                            ? t('common-logged')
                            : t('common-not-logged')}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.last_update}</TableCell>
                      <TableCell align="right">
                        <span
                          className="icon-more"
                          aria-haspopup="true"
                          aria-expanded={
                            Boolean(anchorEl[row.id]) ? 'true' : undefined
                          }
                          onClick={handleClick(row.id)}
                        ></span>
                        <Menu
                          className="submenu"
                          id={`${row.id}`}
                          anchorEl={anchorEl[row.id]}
                          open={Boolean(anchorEl[row.id])}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem
                            onClick={
                              row.status !== 'exited'
                                ? () =>
                                  navigate(
                                    `/retailer/hac-profile/${row.id}`
                                  )
                                : null
                            }
                            className={
                              row.status === 'exited' ? 'disabled' : ''
                            }
                          >
                            {t('common-profile')}
                          </MenuItem>
                          <MenuItem
                            onClick={
                              row.status !== 'exited'
                                ? () =>
                                  navigate(`/retailer/vehicles/${row.id}`)
                                : null
                            }
                            className={
                              row.status === 'exited' ? 'disabled' : ''
                            }
                          >
                            {t('pma-hac-tab-option2')}
                          </MenuItem>
                          <MenuItem
                            onClick={
                              row.status !== 'exited'
                                ? () =>
                                  navigate(`/retailer/preferences/${row.id}`)
                                : null
                            }
                            className={
                              row.status === 'exited' ? 'disabled' : ''
                            }
                          >
                            {t('pma-hac-tab-option3')}
                          </MenuItem>
                          <MenuItem
                            onClick={
                              row.status !== 'exited'
                                ? () =>
                                  navigate(`/retailer/shared-notes/${row.id}`)
                                : null
                            }
                            className={
                              row.status === 'exited' ? 'disabled' : ''
                            }
                          >
                            {t('pma-hac-tab-option7')}
                          </MenuItem>
                        </Menu>
                        {/*                        <Link
                          to={`/retailer/hac-profile/${row.id}`}
                          className="profile"
                        >
                          <span className="icon-chevron-right"></span>
                          <span className="text">{t('common-profile')}</span>
                        </Link>*/}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('retailer-main-active-empty-title')}
              <small>{t('retailer-main-active-empty-text')}</small>
            </h2>
            <Link to="/retailer/nominate-hac" className="btn fit">
              <span className="icon-plus"></span>{' '}
              {t('retailer-main-active-action')}
            </Link>
          </div>
        )
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHacActive;
