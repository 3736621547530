import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import decodeToken from '../../../../utils/decode-token';
import ModalNominateOnBehalf from '../modal-nominate-on-behalf/modal-nominate-on-behalf';
import { Alert, Snackbar } from '@mui/material';

function TableHac({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const userId = decodeToken(token).id;

  const [hacs, setHacs] = useState([]);
  const [filteredHacs, setFilteredHacs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [markets] = useState([
    { name: 'AU_CZ_SK', countries: ['AT', 'CZ', 'SK'] },
    { name: 'Benelux', countries: ['BE', 'LUX', 'NE'] },
    { name: 'France', countries: ['FR'] },
    { name: 'Germany', countries: ['DE'] },
    { name: 'Iberia', countries: ['ES', 'PT'] },
    { name: 'Italy', countries: ['IT'] },
    { name: 'Switzerland', countries: ['CH'] },
    { name: 'Turkey', countries: ['TR'] },
  ]);

  const [associatedMarket, setAssociatedMarket] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        let url = '';
        if (
          role === 'admin' ||
          role === 'marketAdmin' ||
          role === 'countryAdmin'
        ) {
          url = `${process.env.REACT_APP_API_URL}/admin/${userId}`;
        }

        if (!url) {
          console.error('No se pudo construir la URL para la solicitud.');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        if (role === 'marketAdmin') {
          const response = await apiClient.get(url, config);
          if (response.data && response.data.market_area_code) {
            setAssociatedMarket(response.data.market_area_code);
          } else {
            console.warn('No se encontró market_area_code en la respuesta.');
          }
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('alert-server-error'), error);
      }
    };

    fetchData();
  }, [token, userId, role]);

  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
    status: '',
  });
  const [anchorEl, setAnchorEl] = useState({});
  const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura de la modal

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/hacs`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const sortedHacs = response.data.sort((a, b) => {
            // Convertir la fecha de dd-mm-aaaa a aaaa-mm-dd para la comparación
            const [dayA, monthA, yearA] = a.last_update.split('-').map(Number);
            const [dayB, monthB, yearB] = b.last_update.split('-').map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA); // Meses en JavaScript son 0-indexados
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateB - dateA; // Orden descendente por fecha
          });

          setHacs(sortedHacs);
          setFilteredHacs(sortedHacs);
          setDataLoaded(true);
          onDataLoaded();

          const uniqueCountries = [
            ...new Set(response.data.map((hac) => hac.area_code)),
          ];
          setCountries(uniqueCountries);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded, t]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);

      if (selectedMarket) {
        // Actualiza los países en función del mercado seleccionado
        setCountries(selectedMarket.countries);
        // Restablece el filtro de país si el mercado cambia
        setFilters({
          ...filters,
          market: value,
          country: '', // Opcional: Restablece el filtro de país
        });
      } else {
        // Muestra todos los países si no se selecciona un mercado válido
        const allCountries = [...new Set(hacs.map((hac) => hac.area_code))];
        setCountries(allCountries);
        setFilters({
          ...filters,
          market: value,
          country: '', // Opcional: Restablece el filtro de país
        });
      }
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = () => {
    let filtered = [...hacs];
    if (filters.name) {
      filtered = filtered.filter(
        (hac) =>
          hac.name.toLowerCase().includes(filters.name.toLowerCase()) ||
          hac.last_name.toLowerCase().includes(filters.name.toLowerCase())
      );
    }

    if (filters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === filters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((hac) =>
          selectedMarket.countries.includes(hac.area_code)
        );
      }
    }

    if (filters.country) {
      filtered = filtered.filter((hac) => hac.area_code === filters.country);
    }
    if (filters.status) {
      filtered = filtered.filter((hac) => hac.status === filters.status);
    }
    setFilteredHacs(filtered);
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <header>
        <h2>{t('admin-main-tab1-title')}</h2>
        {role !== 'admin' && (
          <button className="btn secondary" onClick={handleOpenModal}>
            + {t('admin-main-tab1-action')}
          </button>
        )}
      </header>
      <ModalNominateOnBehalf open={openModal} onClose={handleCloseModal} />
      {dataLoaded ? (
        hacs.length !== 0 ? (
          <>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={3}>
                  <div className="form-control">
                    <label htmlFor="name">
                      {t('common-name')}/{t('common-last-name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                {role === 'admin' && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets.map((market) => (
                          <option key={market.name} value={market.name}>
                            {t(`market-${market.name}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                {role === 'marketAdmin' && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets
                          .filter((market) =>
                            market.countries.some((country) =>
                              associatedMarket.includes(country)
                            )
                          )
                          .map((market) => (
                            <option key={market.name} value={market.name}>
                              {t(`market-${market.name}`)}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Grid>
                )}
                {(role === 'admin' || role === 'marketAdmin') && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {t(`countries-${country}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="status">{t('common-status')}</label>
                    <select
                      name="status"
                      id="status"
                      className="normal"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-status')}</option>
                      <option value="nominated">{t('common-nominated')}</option>
                      <option value="active">{t('common-active')}</option>
                      <option value="exited">{t('common-exited')}</option>
                    </select>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <div className="actions">
                    <button className="btn" onClick={applyFilters}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="summary">
              <strong>
                {t('common-total-results')}: <span>{filteredHacs.length}</span>
              </strong>{' '}
              |
              <span>
                {t('common-users-active')}:{' '}
                <span>
                  {filteredHacs.filter((hac) => hac.status === 'active').length}
                </span>
              </span>{' '}
              |
              <span>
                {t('common-users-nominated')}:{' '}
                <span>
                  {
                    filteredHacs.filter((hac) => hac.status === 'nominated')
                      .length
                  }
                </span>
              </span>
            </div>
            <div className="table-wrapper">
              {filteredHacs.length !== 0 ? (
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="user-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{t('common-name')}</TableCell>
                      <TableCell align="left">
                        {t('common-last-name')}
                      </TableCell>
                      <TableCell align="center" width="100">
                        {t('common-pma')}
                      </TableCell>
                      <TableCell align="center">
                        {t('common-retailer')}
                      </TableCell>
                      <TableCell align="center">{t('common-market')}</TableCell>
                      <TableCell align="center">
                        {t('common-country')}
                      </TableCell>
                      <TableCell align="center">{t('common-status')}</TableCell>
                      <TableCell align="center">
                        {t('common-last-update')}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredHacs.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="center" width="100">
                          {row.first_pma_email}{' '}
                        </TableCell>
                        <TableCell align="center">{row.retailer}</TableCell>
                        <TableCell align="center">
                          {t(`market-${row.market}`)}
                        </TableCell>
                        <TableCell align="center">
                          {t(`countries-${row.area_code}`)}
                        </TableCell>
                        <TableCell align="center">
                          {t(`common-${row.status}`)}
                        </TableCell>
                        <TableCell align="center">{row.last_update}</TableCell>
                        <TableCell align="right">
                          <span
                            className="icon-more"
                            aria-controls={
                              anchorEl[row.id] ? `menu${index}` : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(anchorEl[row.id]) ? 'true' : undefined
                            }
                            onClick={handleClick(row.id)}
                          ></span>
                          <Menu
                            className="submenu"
                            id={`${row.id}`}
                            anchorEl={anchorEl[row.id]}
                            open={Boolean(anchorEl[row.id])}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem
                              onClick={
                                row.status !== 'exited'
                                  ? () =>
                                      navigate(
                                        `/admin/nominating-retailer/${row.id}`
                                      )
                                  : null
                              }
                              className={
                                row.status === 'exited' ? 'disabled' : ''
                              }
                            >
                              {t('admin-main-tab1-option4')}
                            </MenuItem>
                            <MenuItem
                              onClick={
                                row.status !== 'exited'
                                  ? () =>
                                      navigate(`/admin/assigned-pmas/${row.id}`)
                                  : null
                              }
                              className={
                                row.status === 'exited' ? 'disabled' : ''
                              }
                            >
                              {t('admin-main-tab1-option5')}
                            </MenuItem>
                            <MenuItem
                              onClick={
                                row.status !== 'exited' && role === 'countryAdmin'
                                  ? () =>
                                    navigate(`/admin/shared-notes/${row.id}`)
                                  : null
                              }
                              className={
                                row.status === 'exited' || role !== 'countryAdmin' ? 'disabled' : ''
                              }
                            >
                              {t('pma-hac-tab-option7')}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="no-result">
                  <h2 className="txt-center">{t('admin-main-title-empty')}</h2>
                </div>
              )}
            </div>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert severity="error" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('admin-main-tab1-title-empty')}
              <small>{t('admin-main-tab1-text-empty')}</small>
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
    </>
  );
}

export default TableHac;
